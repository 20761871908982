import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { confirmEmail } from '../../actions/auth';
import { getFieldFromURLParams } from '../../utils/functions';
import FinishPage from '../../components/Steps/FinishPage';

const ConfirmEmail = (props) => {
  const { confirmEmail } = props;
  const [message, setMessage] = useState('');

  useEffect(() => {
    const otp = getFieldFromURLParams('otp');
    console.log('===OTP', otp);
    confirmEmail(otp, () => {
      setMessage('Your email has been confirmed.');
    }, () => {
      console.log('===ERROR');
      setMessage('Your email is not confirmed.');
    });
  }, []);

  return (
    <FinishPage message={message} />
  );
};

const mapStateToProps = (store) => ({
  country: store.steps.stepsData.country,
});

const mapDispatchToProps = {
  confirmEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);
