import React from 'react';
import cn from 'classnames';

import styles from './Steps.module.css';

const FinishPage = ({ message = 'Thank you! Please, check your email'}) => (
  <div className={cn(styles.stepWrapper, styles.wrapperPadding, styles.brownBackground)}>
    <div className={cn(styles.finishPage, styles.plateAnimation)}>
      <div className={styles.stepHeader}>
        {message}
      </div>
    </div>
  </div>
);

export default FinishPage;