import { authAPI } from '../api/auth';
import { actionWrapper } from './actionWrapper';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_PART_USER_DATA = 'SET_PART_USER_DATA';

export const confirmEmail = (otp, callback, errorCallback) => actionWrapper(async () => {
  const confirmResult = await authAPI.confirmEmail(otp);
  callback?.();
  console.log('===confirmResult', confirmResult);
}, errorCallback);

const logoutProceed = () => {
  return {
    type: SET_USER_DATA,
    payload: null,
  };
};

export const logout = () => () => {
  localStorage.removeItem('user');
  window.location = '/';
  return logoutProceed();
};